import React, {FC} from 'react';
import classNames from 'classnames';
import {Location} from '@reach/router';
import {Link} from 'gatsby';
import {TabType} from '../../types';
import './styles.scss';

const Tabs: FC<TabType> = ({dataTabs}): JSX.Element => {
  let tabs = [
    {
      name: 'How does it work?',
      path: '/about',
    },
    {
      name: 'Team',
      path: 'https://hexact.io/leadership/',
    },
    {
      name: 'Career',
      path: 'https://hexact.io/careers/',
    },
    // {
    //   name: 'Reviews',
    //   path: '/reviews-press',
    // },
    {
      name: 'Media Kit',
      path: '/media-kit',
    },
    {
      name: 'Roadmap',
      path: '/roadmap',
    },
  ];

  if (dataTabs) {
    tabs = dataTabs;
  }

  return (
    <Location>
      {({location}) => (
        <ul className="tab-nav tab-nav-tabs tab_develor_tab mb-30">
          {tabs.map(tab => {
            return (
              <li key={tab.name} className="tab-nav-item">
                {tab.path.includes('https://') ? (
                  <a href={tab.path} target="_blank" className="tab-nav-link" rel='noopener'>
                    {tab.name}
                  </a>
                ) : (
                  <Link
                    to={tab.path}
                    className={classNames('tab-nav-link', {
                      'tab-active': tab.path === location.pathname || `${tab.path}/` === location.pathname,
                    })}
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {tab.name}
                  </Link>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </Location>
  );
};

export default Tabs;
