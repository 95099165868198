import React from 'react';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import Tabs from '../../components/tabs';

const StepanImg = 'https://storage.googleapis.com/website.hexometer.com/team/Stepan.jpg';
const ChrisImg = 'https://storage.googleapis.com/website.hexometer.com/team/Chris.jpg';
const VaheImg = 'https://storage.googleapis.com/website.hexometer.com/team/Vahe.jpg';
const LusineImg = 'https://storage.googleapis.com/website.hexometer.com/team/Lusine.jpg';

import './style.scss';

const peoples = [
  {
    name: 'Stepan',
    position: 'CEO',
    img: StepanImg,
    description:
      'Stepan is an entrepreneurial catalyst. In a previous life, he co-founded one of the early pioneers of food delivery services now popularised by the likes of Uber Eats. Now his mission is to re-invent the very concept of website measuring and monitoring to help even the playing field for businesses of all sizes.',
  },
  {
    name: 'Chris',
    position: 'CMO & co-founder',
    img: ChrisImg,
    description:
      'Chris is an eCommerce maverick who loves to challenge the status quo. As a natural born possibilitarian with a healthy dose of OCD, he brings vision, strategy and a keen attention to detail.',
  },
  {
    name: 'Vahe',
    position: 'CTO & co-founder',
    img: VaheImg,
    description:
      'Vahe is a startup veteran with a decade of experience coding and architecting scalable platforms. He leads our epic development team to keep us at the bleeding edge.',
  },
  {
    name: 'Lusine',
    position: 'Project manager',
    img: LusineImg,
    description:
      'As they say, teamwork makes for dream work. Lusine is our in-house chatterbox, who helps us run an agile ship, ensuring all our teams are talking to each other and delivering on time.',
  },
];
import {Container, Row, Col} from 'react-bootstrap';

import waves from '../../img/teamImages/waves-icon.svg';
import dotsIcon from '../../img/teamImages/dots-icon.svg';
import shape1 from '../../img/teamImages/shape1.svg';
import shape2 from '../../img/teamImages/shape2.svg';
import shape3 from '../../img/teamImages/shape3.svg';
import shape4 from '../../img/teamImages/shape4.svg';
import ellipse from '../../img/teamImages/ellipse.svg';

import teamImg from '../../img/teamImages/team-main-image.jpg';
import teamImg1 from '../../img/teamImages/team-image-1.png';
import teamImg2 from '../../img/teamImages/team-image-2.png';

import Button from '../../components/button';

const Team: React.FC = (): JSX.Element => {
  return (
    <div className="shapes-wrapper" style={{position: 'relative'}}>
      <img src={dotsIcon} className="dots-icon-1 display" alt="" />
      <img src={dotsIcon} className="dots-icon-2 display" alt="" />
      <img src={dotsIcon} className="dots-icon-3 display" alt="" />

      <img src={shape1} className="shape-1 display" alt="" />
      <img src={shape2} className="shape-2 display" alt="" />
      <img src={shape3} className="shape-3 display" alt="" />

      <Layout>
        <PageHero
          title="Meet the Hexometer team"
          subtitle="Here's to the crazy ones. The misfits. 
      The rebels. The troublemakers. The round pegs in the square holes.
       The ones who see things differently, working tirelessly behind the scenes so you can change the world."
        />

        <div style={{margin: 'auto'}}>
          <Tabs />
        </div>

        <div className="team-main-wrapper">
          <section className="header-image-section">
            <Container>
              <img src={dotsIcon} className="dots-icon-header display" alt="" />
              <div className="ellipse">
                <img src={ellipse} className="display" alt="" />
                <img src={shape2} className="shape-4 display" alt="" />
              </div>
              <Row className="header-image">
                <Col>
                  <img src={teamImg} alt="Two developers photo" />
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section-background founder-section py-5">
            <Container>
              <Row className="middle-section">
                <Col md={6} style={{position: 'relative'}}>
                  <div style={{zIndex: 100}}>
                    <img src={teamImg1} alt="Company founders photo" />
                    <img src={waves} className="wave-1 display" alt="" />
                    <div className="ellipse1">
                      <img src={ellipse} className="display" alt="" />
                      <img src={shape4} className="shape-4 display" alt="" />
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <h1 className="h1-image-header h1-info">Get comprehensive peace of mind</h1>
                  <p className="p-info">
                    Meet Hexometer, your AI sidekick that works 24/7 to catch problems before they affect your business.
                  </p>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="py-5">
            <Container>
              <Row className="text-center info-section">
                <Col>
                  <h1 className="h1-info-header">
                    Trusted by 20,000+ businesses &amp; compatible with over 100+ platforms
                  </h1>
                  <p className="p-header">
                    Hexometer monitors your website 24/7, to catch Availability, Performance, User experience, SEO,
                    Health and Security problems before they affect your customers.
                  </p>
                </Col>
              </Row>
              <Row className="my-5 info-wrapper text-center">
                <img src={waves} className="wave-stats display" alt="" />
                <Col lg={4}>
                  <span>
                    <h1 className="h1-margin">50K+</h1>
                    <p>Websites monitored</p>
                  </span>
                </Col>
                <Col lg={4}>
                  <span>
                    <h1>192 million</h1>
                    <p>Pages crawled last month</p>
                  </span>
                </Col>
                <Col lg={4}>
                  <span>
                    <h1>88255</h1>
                    <p>Notifications sent last month</p>
                  </span>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section-background py-5">
            <Container>
              <Row className="middle-section">
                <Col md={6}>
                  <h1 className="h1-image-header h1-info">Different perspectives gives better results</h1>
                  <p className="p-info">
                    Our founding team is global bringing experience from marketing, advertising, eCommerce,
                    infrastructure, coding, and theoretical physics.
                  </p>
                </Col>
                <Col md={6}>
                  <img src={waves} className="wave-2 display" alt="" />
                  <img src={teamImg2} alt="Two team members photo" />
                </Col>
              </Row>
            </Container>
          </section>

          <section>
            <Container className="team-block">
              <Row className="pt-5">
                {peoples.map((person, i) => {
                  return (
                    <Col key={i} lg={3} sm={6}>
                      <div className="ex_team_item">
                        <img src={person.img} alt="" className="img" />
                        <div className="team_content">
                          <h3 className="">{person.name}</h3>
                          <h5>{person.position}</h5>
                        </div>
                        <div className="hover_content">
                          <p className="name">{person.name}</p>
                          <p className="position">{person.position}</p>
                          <p>{person.description}</p>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>

              <Row className="text-center mt-5">
                <Col className="text-center px-5">
                  <h1 className="h1-info">Looking for your next challenge?</h1>
                  <p className="p-header">
                    We are on a mission to empower businesses with innovative applications and that requires an ever
                    expanding team of ambitious change-makers!
                  </p>
                  <p className="p-header">
                    If you’re an A player and want to join a dynamic startup, check out our latest vacancies below:
                  </p>
                </Col>
              </Row>

              <Row className="my-5">
                <Col className="d-flex align-items-center justify-content-center">
                  <Button onClick={() => (window.location.href = 'https://hexact.io/careers')}>
                    See current openings
                  </Button>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </Layout>
    </div>
  );
};

export default Team;
